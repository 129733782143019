.settingsListItem:hover {
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.85);
}
.settingsListItem {
  color: rgba(0, 0, 0, 0.45);
}
.settingsListItem > .ant-list-item-meta > .ant-list-item-meta-content > .ant-list-item-meta-title {
  color: rgba(0, 0, 0, 0.45)
}
.settingsListItem > .ant-list-item-meta > .ant-list-item-meta-content > .ant-list-item-meta-title:hover {
  color: rgba(0, 0, 0, 0.85)
}