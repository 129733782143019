.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100vh;
}
.leftContainer {
  background-image: linear-gradient(141.66deg, rgba(0, 86, 184, 0.8), rgba(99, 139, 24, 0.8)), url("../../../shared//img/login-background.png");
  opacity: 0.8;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-grow: 1;
  background-color: white;
}
.formContainer {
  max-width: 400px;
  width: 100%;
}
.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
}
.ant-btn-primary {
  border-color: var(--primary);
  background-color: var(--primary);
  height: 40px;
  border-radius: var(--border-radius);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.recaptchaContainer {
  margin: 1rem 0 1rem 0;
  display: flex;
  justify-content: center;
  width: 100%;
}