.navbarBtn {
  border: none !important;
  box-shadow: none !important;
}
.drawerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;
  width: 100%;
  margin-bottom: 0.5rem;
  padding-top: 2rem;
}
.drawerHeader p {
  margin: 0.5rem 0 0 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.drawerHeader span {
  margin: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.5;
}