@import '~antd/dist/antd.css';
@import 'animate.css';
@import-normalize;

:root {
  --primary: #0056B8;
  --danger: #d9363e;
  --primary-font: 'Roboto';
  --border-radius: 4px;
}
body {
  background-color: #f0f2f5;
}
.textDanger {
  color: var(--danger);
}
.bgTransparent {
  background-color: transparent !important;
}
.w100 {
  width: 100% !important;
}
.mx1 {
  margin: auto 0.5rem auto 0.5rem !important;
}
.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mAuto {
  margin: auto !important;
}
.m1 {
  margin: 0.5rem !important;
}
.mt1 {
  margin-top: 0.5rem !important;
}
.mt2 {
  margin-top: 1rem !important;
}
.mt4 {
  margin-top: 2rem !important;
}
.mr1 {
  margin-right: 0.5rem !important;
}
.ml1 {
  margin-left: 0.5rem !important;
}
.mb1 {
  margin-bottom: 0.5rem !important;
}
.mb2 {
  margin-bottom: 1rem !important;
}
.mb3 {
  margin-bottom: 1.5rem !important;
}
.p4 {
  padding: 2rem !important;
}
.p1 {
  padding: 0.5rem !important;
}
.p2 {
  padding: 1rem !important;
}
.textCenter {
  text-align: center !important;
}
.textEnd {
  text-align: end !important;
}
.bgWhite {
  background-color: white !important;
}
.dFlex {
  display: flex !important;
}
.dNone {
  display: none !important;
}
.dBlock {
  display: block !important;
}
.flexColumn {
  flex-direction: column !important;
}
.justifyCenter {
  justify-content: center !important;
}
.alignItemsEnd {
  align-items: flex-end !important;
}
.alignItemsCenter {
  align-items: center !important;
}
.fz14 {
  font-size: 14px !important;
}
.ant-input {
  border-radius: 5px !important;
}
.ant-modal-content {
  border-radius: 5px !important;
}
.ant-modal-header {
  border-radius: 5px 5px 0 0 !important;
}
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}
.ant-page-header {
  background-color: white !important;
  border-radius: 5px !important;
}
.ant-drawer-body {
  padding: 0 !important;
}
.ant-descriptions-item-label {
  font-weight: 600 !important;
}
.ant-result {
  background-color: white !important;
  margin-top: 0.5rem;
}
.ant-collapse {
  background-color: white !important;
  border-radius: 5px !important;
}
.site-layout-content {
  max-width: 1366px !important;
  margin: auto !important;
  display: block !important;
}
.pageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  max-width: 1366px;
  background-color: white;
  margin: 0.5rem auto;
  width: 100%;
  border-radius: 5px;
}
.tableContainer {
  overflow: auto;
  margin: 0.5rem 0;
  background-color: white;
}
.ant-upload {
  width: 100% !important;
}
.ant-alert {
  border-radius: 5px;
}
.ant-tag {
  border-radius: 5px !important;
}
.ant-card {
  border-radius: 5px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.ant-picker {
  border-radius: 5px !important;
}
.ant-input-affix-wrapper {
  border-radius: 5px;
}
.ant-input-number-affix-wrapper{
  border-radius: 5px;
}
.ant-card-head-title {
  font-weight: 600 !important;
}
.ant-steps-item-title {
  font-size: 11px !important;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 4px !important;
}
.ant-btn {
  border-radius: 5px !important;
}
.ant-input-number {
  border-radius: 5px;
}
.ant-input-number-group-addon {
  border-radius: 5px;
}
.ant-select-multiple .ant-select-selection-item {
  border-radius: 5px !important;
}
.noBorderColor {
  border-color: transparent !important;
}
