.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formCard {
  width: 100%;
  max-width: 500px;
  margin-top: -10rem;
}
.actionContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}
.actionBtn {
  width: 150px;
} 