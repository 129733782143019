.formContainer {
  display: flex;
  justify-content: center;
  max-width: 1020px;
  width: 100%;
  margin: auto;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 1rem 0;
}
.formColumn {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}
.header {
  font-size: 14px;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}
.formColumn:first-child {
  align-items: flex-end;
}
.formRow {
  height: 40px;
  display: flex;
  align-items: center;
}
.formPercentInput {
  width: 150px;
  margin-left: 0.5rem;
  text-align: center;
}
.formQuantityInput {
  width: 140px;
  text-align: center;
}
.formCurrencyInput {
  width: 140px;
  text-align: center;
}