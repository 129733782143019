.optionContainer {
  display: flex;
  flex-direction: column;
}
.optionRow {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.ant-form-item {
  margin-bottom: 0px !important;
}