.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 0 0 0 !important;
}
h5 {
  margin-top: 0 !important;
}